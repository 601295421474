
  <ng-container *ngIf='mode === 1'>
    <div *ngFor='let userNotifications of userNotifications'>
      <mat-dialog-content class="mat-typography" (scroll)="onScroll($event)">
      <showdown [options]='showdownOptions' [value]='userNotifications.content'></showdown>
      <span class='date-emitted'>
          {{userNotifications.broadcastDate | date : 'medium'}}
        </span>
      </mat-dialog-content>
      <mat-dialog-actions align="end">


        <ng-container *ngFor='let action of userNotifications.expectedActions'>

          <matelex-secondary-button *ngIf="action === 'reject'" [label]="'Refuser'"
                                    (click)='handleRejectNotificationRule(userNotifications)'></matelex-secondary-button>


          <matelex-secondary-button *ngIf="action === 'rejectAndLogout'" [label]="'Refuser et se déconnecter'"
                                    (click)='handleRejectAndLogoutNotificationRule(userNotifications)'></matelex-secondary-button>

          <matelex-stroked-button *ngIf="action === 'close'" [label]="'Fermer'"
                                  (click)='handleCloseNotificationRule(userNotifications)'></matelex-stroked-button>

            <matelex-primary-button  *ngIf="action === 'accept'" [disabled]='enableButtonOnEndScroll && buttonDisabled' [label]="'Accepter'"
                                    (click)='handleAcceptNotificationRule(userNotifications)'></matelex-primary-button>




        </ng-container>
      </mat-dialog-actions>
      </div>
  </ng-container>
  <ng-container *ngIf='mode === 0'>
    <mat-dialog-content class="mat-typography">
    <showdown [options]='showdownOptions' [value]='data.userNotifications.content'></showdown>

    <span class='date-emitted'>
        {{data.userNotifications.broadcastDate | date : 'medium'}}
      </span>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <ng-container *ngFor='let action of data.userNotification.expectedActions'>

          <matelex-primary-button *ngIf="action === 'accept'" [label]="'Accepter'"
                                  (click)='handleAcceptNotificationRule(data.userNotification)'></matelex-primary-button>

          <matelex-secondary-button *ngIf="action === 'reject'" [label]="'Refuser'"
                                    (click)='handleRejectNotificationRule(data.userNotification)'></matelex-secondary-button>

          <matelex-stroked-button *ngIf="action === 'close'" [label]="'Fermer'"
                                  (click)='handleCloseNotificationRule(data.userNotification)'></matelex-stroked-button>

      </ng-container>
    </mat-dialog-actions>
  </ng-container>
