import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';

import * as L from 'leaflet';
import { Markers } from './markers';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';

const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

export class AppMarkerAttributes {
  isMarker: true;
  markerColor?: string;
  [attrName: string]: any;
}

export class AppOlMapMarkerData {
  lon: string;
  lat: string;
  attributes?: AppMarkerAttributes;
}

export class AppMapClickData {
  attributes: AppMarkerAttributes;
}

export class MMarker extends L.Marker {
  private mdAttributes: any;

  constructor(latLng: L.LatLngExpression, options?: L.MarkerOptions) {
    super(latLng, options);
  }

  public getMDAttributes() {
    return this.mdAttributes;
  }

  public setMDAttributes(data: any) {
    this.mdAttributes = data;
  }
}

@Component({
  selector: 'app-ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OlMapComponent implements AfterViewInit, OnChanges {
  public map: L.Map;
  private markersLayerGroup: L.LayerGroup;
  public centerDefault: string[] = ['2.318514', '46.405832'];
  public zoomDefault = 5;
  private defaultMarkerColor = '#0B79D0';

  @Input() selectedMarker = null;
  @Input() public markersCoords: AppOlMapMarkerData[] = [];
  @Input() public center: string[] = this.centerDefault;
  @Input() public zoom: number = this.zoomDefault;
  @Input() public containerWidth = '100%';
  @Input() public containerHeight = '400px';
  @Input() public containerUniqueId = 'containerUniqueId_should_be_defined';

  @Output('clickOnMap') clickOnMapEE = new EventEmitter<AppMapClickData>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}


  ngAfterViewInit(): void {
    try {
      this.initLMap();
    } catch (e) {}
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.markersCoords?.firstChange) {
      return;
    }
    if (changes.markersCoords) {
      this.markersLayerGroup?.clearLayers();
      this.addMarkers(changes.markersCoords.currentValue);
    }
    if (changes.center) {
      let zoom = 8;
      if (this.center == null ){
        this.center = this.centerDefault;
        zoom = this.zoomDefault;
      }
      this.focusOnCoordinates(this.center[1], this.center[0], zoom);
    }
    if (changes.selectedMarker?.currentValue) {
      const marker = this.markersLayerGroup.getLayers().find((layer: any) => {
          const layerLatLng = (layer as L.Marker).getLatLng();
          return layerLatLng.lat === parseFloat(changes.selectedMarker.currentValue[1]) && layerLatLng.lng === parseFloat(changes.selectedMarker.currentValue[0]);
        }) as L.Marker;
      if (marker) {
  //TODO add bounce
        }
    }
  }

  initLMap() {
    if (!this.center || !this.center[0] || !this.center[1]) {
      this.center = this.centerDefault;
      this.zoom = this.zoomDefault;
    }

    this.map = L.map(this.containerUniqueId, {
      preferCanvas: true,
      center: [parseFloat(this.center[1]), parseFloat(this.center[0])],
      zoom: this.zoom
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
    this.markersLayerGroup = L.layerGroup().addTo(this.map);
    this.addMarkers(this.markersCoords);

    this.map.on('click', (evt: L.LeafletEvent) => {
      this.clickOnMapEE.emit({ attributes: null });
    });
  }

  private addMarkers(markersCoords: AppOlMapMarkerData[]): void {
    markersCoords.forEach((markerCoords: AppOlMapMarkerData) => {
      if (!markerCoords.lat || !markerCoords.lon) {
        return;
      }
      if (isNaN(parseFloat(markerCoords.lat)) && isNaN(parseFloat(markerCoords.lon))) {
        return;
      }

      const markerColor = markerCoords.attributes.markerColor ? markerCoords.attributes.markerColor : this.defaultMarkerColor;
      const svgIcon = L.divIcon({
        html: Markers.whiteMarker.replace('###markerFillColor', markerColor).replace('###arialabel', 'dashboard-marker-' + markerCoords.lat + ';' + markerCoords.lon),
        className: '',
        iconSize: [25, 41],
        iconAnchor: [10, 28],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      });

      const pinPosition = L.latLng(parseFloat(markerCoords.lat), parseFloat(markerCoords.lon));
      const marker = new MMarker(pinPosition, { icon: svgIcon });
      marker.setMDAttributes(markerCoords.attributes);

      marker.on('click', (evt: L.LeafletEvent) => {
        const clickedMarker: MMarker = evt.target;
        if (clickedMarker?.getMDAttributes()?.isMarker) {
          this.clickOnMapEE.emit({ attributes: clickedMarker.getMDAttributes() });
        }
      });

      this.markersLayerGroup?.addLayer(marker);
    });

    this.fitMapToMarkers(markersCoords);
  }

  private fitMapToMarkers(markersCoords: AppOlMapMarkerData[]): void {
    const markersLatLngs: L.LatLng[] = [];
    markersCoords.forEach((markerCoords: AppOlMapMarkerData) => {
      if (!markerCoords.lat || !markerCoords.lon) {
        return;
      }
      if (isNaN(parseFloat(markerCoords.lat)) && isNaN(parseFloat(markerCoords.lon))) {
        return;
      }
      markersLatLngs.push(L.latLng(parseFloat(markerCoords.lat), parseFloat(markerCoords.lon)));
    });

    if (markersLatLngs.length) {
      const polyline = L.polyline(markersLatLngs, { color: 'black' });
      this.map?.fitBounds(polyline.getBounds());
    }
  }

  focusOnCoordinates(lat: string, lon: string, zoom: number): void {
    if (this.map) {
      const latNum = parseFloat(lat);
      const lonNum = parseFloat(lon);
      this.map.setView([latNum, lonNum], zoom);
    }
  }



}
