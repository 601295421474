import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslationsResolver } from './shared/resolvers/translations.resolver';
import { AuthGuard } from './shared/guards/auth.guard';
import * as CMP from '@users/components';
import { UsersLoginResolver } from '@shared/resolvers/users-login.resolver';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: localStorage.getItem('defaultLanguage') + '/dashboard' },
  {
    path: ':lang',
    // canActivateChild: [AuthGuard],
    resolve: {
      lang: TranslationsResolver
    },
    children: [

      { path: '',
        loadChildren: () => import('./layouts/dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule),
        canActivate: [AuthGuard] },

      { path: '',
        loadChildren: () => import('./layouts/not-logged-layout/not-logged-layout.module').then(m => m.NotLoggedLayoutModule),
       },


    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
