<mat-form-field   [appearance]='appearance'  [class]='classList' subscriptSizing='dynamic' >
  <mat-label>{{label}}</mat-label>
  <input type="text" matInput [formControl]="control" [matAutocomplete]="auto"  [disabled]='disabled'>
  <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
  <mat-autocomplete [panelWidth]="'auto'"   #auto="matAutocomplete"  [displayWith]="displayFn"  (optionSelected)='optionSelectedEvent($event)'>
    <mat-option  *ngFor="let option of filteredOptions | async" [value]="getValueFromPathObject(this.option,bindValue)">
      {{getValueFromPathObject(this.option,bindLabel)}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>


