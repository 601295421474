<div class="matelex-button">
  <button mat-raised-button
          [color]='"primary"'
          (click)='onClick()'
          [disabled]='disabled'
          [style]="{'background': customColorButton !== '' ? customColorButton + ' !important' : ''}">
    <ng-container *ngIf="!loading">
      <mat-icon class='float-start' *ngIf='icon' fontSet="material-icons-outlined">
        {{icon}}
      </mat-icon>
      <span class='text-color'
        [style]="{'color': customColor !== '' ? customColor + ' !important' : ''}"
      >{{label}}</span>
    </ng-container>
    <div class="loading-container">
      <ng-container *ngIf="loading">
        <mat-spinner diameter="30"></mat-spinner>
      </ng-container>
    </div>
  </button>
</div>
