import { Component, ElementRef, HostListener, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { BannerService } from './banner.service';
import { UserNotification } from '@users/domain/models/user-notification';
import { MatDialog } from '@angular/material/dialog';
import { NotificationRulesDialogComponent } from './notification-rules-dialog/notification-rules-dialog.component';
import { TokenSessionService } from '@shared/services/token.session.service';
import { LanguageService } from '@shared/services/language.service';
import * as Showdown from 'showdown';

interface UserNotificationsInterface {
  infoNotifications: UserNotification[];
  successNotifications: UserNotification[];
  errorNotifications: UserNotification[];
  warningNotifications: UserNotification[];
}
@Component({
  selector: 'anais-banner',
  styleUrls: ['banner.component.scss'],
  templateUrl: 'banner.component.html'
})
export class BannerComponent implements OnInit {
  public finalNotificationRules: UserNotificationsInterface;
  public messages: {
    message: string;
    type: string;
    closeTimeout?: NodeJS.Timeout;
    fadeState?: boolean;
  }[] = [];

  public userNotifications: {
    message: UserNotification[];
    fadeState?: boolean;
    type: string;
  }[] = [];

  public showdownOptions: Showdown.ShowdownOptions = {
    noHeaderId: true,
    emoji: true,
  };
  constructor(private bannerService: BannerService,
              private el: ElementRef,
              private domSanitizer:
                DomSanitizer,
              public dialog: MatDialog,
              public tokenSessionService: TokenSessionService,
              public languageService: LanguageService) {}

  @HostListener('document:click', ['$event'])
  public onClickOutside(event: any): void {
    if (this.messages.length && event.target !== this.el.nativeElement) {
      this.messages.forEach((message, index) => {
        this.messages.splice(index, 1);
      });
    }
  }

  public defineType(criticality: number): string {
    switch (criticality) {
      case 1:
        return 'error';
      case 2:
        return 'alert';
      case 3:
        return 'info';
      case 4:
        return 'success';
      default:
        return '';
    }
  }
  public ngOnInit(): void {
    this.bannerService.subject.subscribe(value => {
      console.log('TOM:', value);
      if (value.isMessage) {
        if (value.duration === -1) {
          this.messages.push({
            message: this.domSanitizer.sanitize(
              SecurityContext.HTML,
              this.domSanitizer.bypassSecurityTrustHtml(value.message)
            ) as any,
            type: value.type
          });
        } else {
          const closeTimeout = setTimeout(() => {
            if (this.messages[0] && this.messages) {
              this.messages[0].fadeState = true;
            }
            setTimeout(() => {
              this.messages.shift();
            }, 1000);
          }, value.duration);
          this.messages.push({
            message: this.domSanitizer.sanitize(
              SecurityContext.HTML,
              this.domSanitizer.bypassSecurityTrustHtml(value.message)
            ) as any,
            type: value.type,
            closeTimeout
          });
        }
      } else {
        if (value.duration === -1 && value?.userNotifications) {
          const infoNotifications: UserNotification[] = [];
          const successNotifications: UserNotification[] = [];
          const errorNotifications: UserNotification[] = [];
          const warningNotifications: UserNotification[] = [];
          const notifications = value.userNotifications;
          console.log(value.userNotifications);

          notifications.forEach(async (n: UserNotification) => {

            const notificationLang = n.notificationRuleLang?.filter(f => f.lang === this.languageService.currentLanguage.toUpperCase())[0];
 //           n.title = notificationLang?.trad.title;
 //           n.summary = notificationLang?.trad.summary;
 //           n.content = notificationLang?.trad.content;
            if (n.type === 'cgv'){
              await this.openSeveralNotificationRules([n]);
            }else{
              switch (n.criticality) {
                case 'critique':
                  errorNotifications.push(n);
                  break;
                case 'avertissement':
                  warningNotifications.push(n);
                  break;
                case 'information':
                  infoNotifications.push(n);
                  break;
                case 'confirmation':
                  successNotifications.push(n);
                  break;
                default:
                  break;
              }

            }
          });
          this.finalNotificationRules = {
            errorNotifications,
            infoNotifications,
            successNotifications,
            warningNotifications
          };
          this.userNotifications.push({
            message: value.userNotification,
            type: this.defineType(value.userNotification?.criticality)
          });
        }
      }
    });
  }

  public async hideNotificationRuleBanner(userNotifications: UserNotification[]): Promise<void> {
    userNotifications.splice(0, userNotifications.length);
  }
  public async openSeveralNotificationRules(userNotifications: UserNotification[]): Promise<void> {

    const dialogRef = this.dialog.open(NotificationRulesDialogComponent, {
      width: '700px',
      data: {userNotifications, idUser: this.tokenSessionService.userInfo._id},
      disableClose : userNotifications.some(n => n.type === 'cgv' ),
    });
  }
  public closeBanner(index: number): void {
    clearTimeout(this.messages[index].closeTimeout);
    this.messages.splice(index, 1);
  }
}
