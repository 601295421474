import { Injectable } from '@angular/core';
import { Equipment } from '@shared/models/equipment';
import { PagedResult } from '@shared/services/paged-result';
import gql from 'graphql-tag';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { GraphqlService } from '../../graphql/graphql.service';
import { CustomerLocation } from '../../shared/models/customer-location';
import { CustomerChainsResult } from './customer-chains-result';
import { CustomerLocationsResult } from './customer-locations-result';
import { EquipmentListSearchCriteria } from './equipment-list-search-criteria.interface';
import { EquipmentsGroupedByCustomerLocationResult } from './equipments-grouped-by-customer-location.result';
import { environment } from '@env/environment';
import { LanguageService } from '@shared/services/language.service';
import { TokenSessionService } from '@shared/services/token.session.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  constructor(private graphqlService: GraphqlService, private languageService: LanguageService, private tokenSessionService: TokenSessionService, private httpClient: HttpClient) {
    // this.getEquipmentsGroupedByCustomerLocation();
    // (START) MOCK DATA
    // this.getMockDnisGroupedByStore();
    // this.getMockDniFilterCountries();
    // this.getMockDniFilterStores();
    // this.getMockDniFilterStoreChains();
    // (END) MOCK DATA
  }
  public equipmentsGroupedByCustomerLocation$$: ReplaySubject<EquipmentsGroupedByCustomerLocationResult> =
    new ReplaySubject<EquipmentsGroupedByCustomerLocationResult>(1);
  public customerLocationChains$$: ReplaySubject<CustomerChainsResult> = new ReplaySubject<CustomerChainsResult>(1);
  public customerLocations$$: ReplaySubject<CustomerLocationsResult> = new ReplaySubject<CustomerLocationsResult>(1);
  public customerLocationsCountries$$: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  // public countries$: Observable<any> | undefined;
  // public storeChains$: Observable<any> | undefined;
  // public stores$: Observable<any> | undefined;

  private updateEquipmentQuery = gql`
    mutation UpdateEquipment($input: EquipmentUpdateInput!) {
      updateOneEquipmentBy(updateByPropName: "id", updateByPropIsObjId: false, input: $input) {
        id
      }
    }
  `;
  private createOrUpdateDeviceExternalTempQuery = gql`
    mutation createOrUpdateDeviceExternalTemp($input: CreateDeviceExternalTemp!) {
      createOrUpdateDeviceExternalTemp(input: $input) {
        id
        serial_number
        type
        type
      }
    }
  `;


  private updateDeviceQuery = gql`
    mutation UpdateDevice($input: DeviceUpdateInput!) {
      updateOneDeviceBy(updateByPropName: "id", updateByPropIsObjId: false, input: $input) {
        id
      }
    }
  `;

  private createPolarBoxAndEquipmentsQuery = gql`
    mutation createPolarboxAndEquipments($input: PolarboxCreateInput!) {
      createPolarboxAndEquipments(input: $input) {
      saved
      message
      }
    }
  `;

  private getEquipmentQuery = gql`
    query getEquipment($id: String!) {
      getEquipmentById(id: $id) {
        _id
        id
        main_device_id {
          _id
          id
        }
        customerLocation {
          _id
        }
      }
    }
  `;

  private countEquipmentsQuery = gql`
  query countEquipments {
      countEquipments {
      _id
      name
      }
    }
  `;
  private countEquipmentsHavingAlarmsQuery = gql`
    query countEquipmentsHavingAlarms($onlyEquipmentsHavingCustomerLocation: Boolean!) {
      countEquipmentsHavingAlarms(onlyEquipmentsHavingCustomerLocation: $onlyEquipmentsHavingCustomerLocation){
      totalEquipmentsWithActiveAlarms
      totalEquipmentsWithConnectionAlarm
      }
    }
  `;

  private getUnassignedEquipmentsQuery: any = gql`
    query getUnassignedEquipments($term: String, $sortField: String, $sortDir: String, $page: Float!, $perPage: Float!) {
      getUnassignedEquipments(criteria: { term: $term, sortField: $sortField, sortDir: $sortDir, page: $page, perPage: $perPage }) {
        itemsPage {
          id
          name
        }
        total
      }
    }
  `;

  private equipmentsGroupedByCustomerLocationQuery = gql`
    query searchEquipmentsForEquipmentListScreen(
      $categories:[String!]
      $term: String
      $hasEnergyModule:Boolean
      $customerChain: [ID!]
      $country: [String!]
      $customerLocation: [ID!]
      $installers: [ID!]
      $controllerHardware:[String!]
      $onlyHavingActiveAlarms: Boolean
      $onlyEquipmentsWithForeseenControl: Boolean
      $getOnlyTotalEquipmentsWithActiveAlarms: Boolean
      $page: Float
      $perPage: Float
    ) {
      searchEquipmentsForEquipmentListScreen(
        criteria: {
          categories : $categories
          term: $term
          customerChain: $customerChain
          hasEnergyModule: $hasEnergyModule
          country: $country
          customerLocation: $customerLocation
          controllerHardware: $controllerHardware
          installers: $installers
          onlyHavingActiveAlarms: $onlyHavingActiveAlarms
          onlyEquipmentsWithForeseenControl: $onlyEquipmentsWithForeseenControl
          getOnlyTotalEquipmentsWithActiveAlarms: $getOnlyTotalEquipmentsWithActiveAlarms
          page: $page
          perPage: $perPage
        }
      ) {
        totalEquipmentsWithActiveAlarms
        total
        totalWithForeseenControl
        totalEquipmentsWithConnectionAlarm
        customerLocationsPage {
        totalEquipmentsAlarmWithoutConnectionAlarm
          _id
          name
          phone
          email
          country
          postalCode
          city
          streetAndNumber
          latitude
          longitude
          is_active
          customerLocationEquipmentsWithAlarmsCount
          installer {
            _id
            name
            logo
            chain {
              _id
              name
            }
            phone
            email
          }
          totalEquipmentsWithConnectionAlarm
          equipments {
            _id
            id
            name
            inactive
            last_tare_date
            low_level_limit
            equipmentActiveAlarmsCount
            equipmentActiveAlarms {
              id
              date_time
              translations {
                locale
                fields {
                  field
                  value
                }
              }
              alarm_type {
                _id
                color
                backgroundColor
                icon
                translations {
                  locale
                  fields {
                    field
                    value
                  }
                }
              }
            }
            main_device_id {
            id
            hardware_version
            license_enabled
            }
            status {
              _id
              color
              icon
              colorDistanceFromRed
              translations {
                locale
                fields {
                  field
                  value
                }
              }
            }
            unit_flags_and_alarms {
              average_reference
              simultaneous_unit_status
            }
          }
        }
      }
    }
  `;

  private equipmentFilterCustomerChainsQuery: any = gql`
    query getEquipmentFilterCustomerChains {
      getEquipmentFilterCustomerChains(criteria: { page: 1, perPage: 10000 }) {
        itemsPage {
          _id
          name
        }
        total
      }
    }
  `;

  private equipmentFilterCustomerLocationsQuery: any = gql`
    query getEquipmentFilterCustomerLocations {
      getEquipmentFilterCustomerLocations(criteria: { page: 1, perPage: 10000 }) {
        itemsPage {
          _id
          name
          phone
          email
          country
          postalCode
          city
          streetAndNumber
          latitude
          longitude
        }
        total
      }
    }
  `;

  private equipmentFilterCountryQuery: any = gql`
    query getCustomerLocationsCountries {
      getCustomerLocationsCountries
    }
  `;

  private totalAlarmsCountQuery: any = gql`
    query totalAlarms {
      totalAlarmCount
    }
  `;
  private equipmentInAlarmSubject = new BehaviorSubject(0);
  public equipmentInAlarm$ = this.equipmentInAlarmSubject.asObservable();



  public totalEquipmentEnergyModulesQuery = gql`
    query TotalEquipmentEnergyModules($equipmentId: String!) {
      totalEquipmentEnergyModules(equipmentId: $equipmentId)
    }
  `;
  public equipmentsExportCsv(filter: {  term?: string;
    customerChain?: string[],
    installers?: string[],
    country?: string[],
    customerLocation?: string[],
    status?: string,
    onlyHavingActiveAlarms?: boolean,
    onlyEquipmentsWithForeseenControl?: boolean,
    controllerHardware?: string[],
    hasEnergyModule?: boolean|null,
    categories: string[]}): Observable<any>{
    if (filter?.status === 'onlyHavingActiveAlarms') {
      filter.onlyHavingActiveAlarms = true;
    } else if (filter?.status === 'onlyEquipmentsWithForeseenControl') {
      filter.onlyEquipmentsWithForeseenControl = true;
    }
    return this.httpClient.post(`${document.location.protocol}//${document.location.hostname}${environment.apiPort}/${ this.languageService.currentLanguage}/equipments/export/csv`, filter, {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: {
        Authorization: `Bearer ${this.tokenSessionService.getToken()}`
      }
    });
  }
  public async createPolarBoxAndEquipments(input: Equipment): Promise<any> {
    try {
      return await this.graphqlService.mutate(this.createPolarBoxAndEquipmentsQuery, { input })
        .pipe(
          take(1),
          map((result: any) => {
            return result?.createPolarboxAndEquipments;
          })
        )
        .toPromise();
    } catch (e) {
      console.error('Error:', e);
    }
  }
  public async updateEquipment(input: Equipment): Promise<void> {
    try {
      await this.graphqlService.mutate(this.updateEquipmentQuery, { input }).toPromise();
    } catch (e) {
      console.error('Error:', e);
    }
  }

  public async updateDevice(input: any): Promise<void> {
    try {
      await this.graphqlService.mutate(this.updateDeviceQuery, { input }).toPromise();
    } catch (e) {
      console.error('Error:', e);
    }
  }


  public async createOrUpdateDeviceExternalTemp(input: any): Promise<any> {
    try {
      return await this.graphqlService.mutate(this.createOrUpdateDeviceExternalTempQuery, { input }).pipe(
        take(1),
        map((result: any) => {
          return result?.createOrUpdateDeviceExternalTemp;
        })
      ).toPromise();
    } catch (e) {
      console.error('Error:', e);
    }
  }

  public async totalEquipmentEnergyModules(id: string): Promise<number> {
    return await this.graphqlService
      .query(this.totalEquipmentEnergyModulesQuery, { equipmentId: id }, false)
      .pipe(
        take(1),
        map(result => result.data.totalEquipmentEnergyModules)
      )
      .toPromise();
  }

  public async getEquipment(id: string): Promise<any> {
    return await this.graphqlService
      .query(this.getEquipmentQuery, { id }, false)
      .pipe(
        take(1),
        map((result: any) => {
          return result.data?.getEquipmentById;
        })
      )
      .toPromise();
  }

  public async getUnassignedEquipments(criteria: any): Promise<any> {
    const gqlParams = {} as any;

    if (criteria.sort) {
      gqlParams.sortField = criteria.sort.field;
      gqlParams.sortDir = criteria.sort.direction;
    }
    if (criteria?.filters?.term) {
      gqlParams.term = criteria?.filters?.term;
    }
    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page + 1;
      gqlParams.perPage = criteria.pagination.perPage;
    }

    return await this.graphqlService
      .query(this.getUnassignedEquipmentsQuery, gqlParams, false)
      .pipe(
        take(1),
        map((result: any) => {
          const data: PagedResult<Equipment> = {
            total: result.data.getUnassignedEquipments.total,
            itemsPage: result.data.getUnassignedEquipments.itemsPage
          };
          return data;
        })
      )
      .toPromise();
  }

  public getEquipmentsGroupedByCustomerLocation(
    criteria: EquipmentListSearchCriteria,
    getOnlyTotalEquipmentsWithActiveAlarms: boolean = false
  ): Observable<EquipmentsGroupedByCustomerLocationResult> {
    const gqlArguments: any = {};
    if (criteria?.filters?.term) {
      gqlArguments.term = criteria?.filters?.term;
    }

    if (criteria?.filters?.categories) {
      gqlArguments.categories = criteria?.filters?.categories;
    }

    if (criteria?.filters?.hasEnergyModule !== null || criteria.filters.hasEnergyModule !== undefined) {
      gqlArguments.hasEnergyModule = criteria?.filters?.hasEnergyModule;
    }
    if (criteria?.filters?.country && criteria?.filters?.country?.length) {
      gqlArguments.country = criteria?.filters?.country;
    }
    if (criteria?.filters?.customerChain && criteria?.filters?.customerChain?.length) {
      gqlArguments.customerChain = criteria?.filters?.customerChain;
    }
    if (criteria?.filters?.customerLocation && criteria?.filters?.customerLocation?.length) {
      gqlArguments.customerLocation = criteria?.filters?.customerLocation;
    }
    if (criteria?.filters?.installers && criteria?.filters?.installers?.length) {
      gqlArguments.installers = criteria?.filters?.installers;
    }

    if (criteria?.filters?.controllerHardware && criteria?.filters?.controllerHardware?.length) {
      gqlArguments.controllerHardware = criteria?.filters?.controllerHardware;
    }

    if (criteria?.filters?.status === 'onlyHavingActiveAlarms') {
      gqlArguments.onlyHavingActiveAlarms = true;
    } else if (criteria?.filters?.status === 'onlyEquipmentsWithForeseenControl') {
      gqlArguments.onlyEquipmentsWithForeseenControl = true;
    }

    if (getOnlyTotalEquipmentsWithActiveAlarms) {
      gqlArguments.getOnlyTotalEquipmentsWithActiveAlarms = true;
    }

    if (criteria?.pagination.page) {
      gqlArguments.page = criteria?.pagination.page + 1;
    }
    if (criteria?.pagination.perPage) {
      gqlArguments.perPage = criteria?.pagination.perPage;
    }

    return this.graphqlService
      .query(this.equipmentsGroupedByCustomerLocationQuery, gqlArguments, false)
      .pipe(map(result => result.data.searchEquipmentsForEquipmentListScreen))
      .pipe(
        map((customerLocationsInfo: EquipmentsGroupedByCustomerLocationResult) => {
          console.log('EquipmentService getEquipmentsGroupedByCustomerLocation customerLocationsInfo', customerLocationsInfo);

          const customerLocationsResult: EquipmentsGroupedByCustomerLocationResult = {
            customerLocationsPage: [],
            total: 0,
            totalEquipmentsWithActiveAlarms: customerLocationsInfo.totalEquipmentsWithActiveAlarms,
            totalWithForeseenControl: customerLocationsInfo.totalWithForeseenControl,
            totalEquipmentsWithConnectionAlarm: customerLocationsInfo.totalEquipmentsWithConnectionAlarm
          };

          if (getOnlyTotalEquipmentsWithActiveAlarms) {
            customerLocationsResult.totalEquipmentsWithActiveAlarms = customerLocationsInfo.totalEquipmentsWithActiveAlarms;
          } else {
            if (!Array.isArray(customerLocationsInfo.customerLocationsPage)) {
              return customerLocationsResult;
            }

            customerLocationsResult.customerLocationsPage = customerLocationsInfo.customerLocationsPage.map(customerLocationInfo => {
              console.log('EquipmentService getEquipmentsGroupedByCustomerLocation customerLocationInfo', customerLocationInfo);
              const customerLocation: CustomerLocation = new CustomerLocation(customerLocationInfo);
              console.log('EquipmentService getEquipmentsGroupedByCustomerLocation customerLocation', customerLocation);
              return customerLocation;
            });

            customerLocationsResult.total = customerLocationsInfo.total;
          }
          return customerLocationsResult;
        })
      );
  }

  public loadEquipmentAlarmsCount(criteria: EquipmentListSearchCriteria,
                                  getOnlyTotalEquipmentsWithActiveAlarms: boolean = false): Observable<EquipmentsGroupedByCustomerLocationResult> {
    const newCriteria = {...criteria};
    newCriteria.pagination.perPage = 1000;
    newCriteria.pagination.page = 0;
    return this.getEquipmentsGroupedByCustomerLocation(newCriteria, getOnlyTotalEquipmentsWithActiveAlarms);
  }
  public loadEquipmentsGroupedByCustomerLocation(
    criteria: EquipmentListSearchCriteria,
    getOnlyTotalEquipmentsWithActiveAlarms: boolean = false
  ): void {
    this.getEquipmentsGroupedByCustomerLocation(criteria, getOnlyTotalEquipmentsWithActiveAlarms).subscribe(
      (customerLocationsResult: EquipmentsGroupedByCustomerLocationResult) => {
        this.equipmentsGroupedByCustomerLocation$$.next(customerLocationsResult);
      }
    );
  }

  public getEquipmentListFilterCustomerLocationChains(): void {
    this.graphqlService
      .query(this.equipmentFilterCustomerChainsQuery, {}, false)
      .pipe(
        map((result: any) => {
          const customerChainsResult: CustomerChainsResult = {
            customerChainsPage: result.data.getEquipmentFilterCustomerChains.itemsPage,
            total: result.data.getEquipmentFilterCustomerChains.total
          };
          return customerChainsResult;
        })
      )
      .subscribe((customerChainsResult: CustomerChainsResult) => {
        this.customerLocationChains$$.next(customerChainsResult);
      });
  }

  public getEquipmentFilterCustomerLocations(): void {
    this.graphqlService
      .query(this.equipmentFilterCustomerLocationsQuery, {}, false)
      .pipe(
        map((result: any) => {
          const customerLocationsResult: CustomerLocationsResult = {
            customerLocationsPage: result.data.getEquipmentFilterCustomerLocations.itemsPage,
            total: result.data.getEquipmentFilterCustomerLocations.total
          };
          return customerLocationsResult;
        })
      )
      .subscribe((customerLocationsResult: CustomerLocationsResult) => {
        this.customerLocations$$.next(customerLocationsResult);
      });
  }

  public getEquipmentFilterCountries(): void {
    this.graphqlService
      .query(this.equipmentFilterCountryQuery, {}, false)
      .pipe(
        map((result: any) => {
          const customerLocationsCountries: string[] = result.data.getCustomerLocationsCountries;
          return customerLocationsCountries;
        })
      )
      .subscribe((customerLocationsCountriesResult: string[]) => {
        this.customerLocationsCountries$$.next(customerLocationsCountriesResult);
      });
  }

  public async countEquipments(): Promise<any> {
    return await this.graphqlService
      .query(this.countEquipmentsQuery, {}, false)
      .pipe(
        take(1),
        map((result: any) => {
          return result.data?.countEquipments;
        })
      ).toPromise();
  }
  public async countEquipmentsHavingAlarms(onlyEquipmentsHavingCustomerLocation: boolean): Promise<any> {
    return await this.graphqlService
      .query(this.countEquipmentsHavingAlarmsQuery, { onlyEquipmentsHavingCustomerLocation }, false)
      .pipe(
        take(1),
        map((result: any) => {
          this.equipmentInAlarmSubject.next(result.data.countEquipmentsHavingAlarms);
          return result.data?.countEquipmentsHavingAlarms;
        })
      )
      .toPromise();
  }


}
