import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Permission } from '../../users/domain/services/user.enums';
import { UserService } from '../../users/domain/services/user.service';

@Directive({
  selector: '[matx-if-has-permission]'
})
export class MatxIfHasPermission implements OnInit, AfterViewInit {
  @Input('matx-if-has-permission') permissions: string[] = [];

  private backupElementDisplay: string | null = null;

  constructor(private elementRef: ElementRef, private userService: UserService) {}

  ngOnInit(): void {
    this.backupElementDisplay = this.elementRef.nativeElement.style.display;
    if (this.permissions.length > 0){
      this.elementRef.nativeElement.style.display = 'none';
      this.elementRef.nativeElement.classList.add('d-none');
    }
  }

  ngAfterViewInit(): void {
    this.checkAccess();
  }

  private checkAccess(): void {
    if (this.userService.loggedInUserHasPermissions(this.permissions as Permission[])) {
      this.elementRef.nativeElement.style.display = this.backupElementDisplay;
      this.elementRef.nativeElement.classList.remove('d-none');
    }
  }
}
