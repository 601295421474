import { BaseModel } from './base.model';
import { DeviceType } from './device-type.enum';

export enum NetworkConnectionType {
  WIFI = 'wifi',
  WLAN = 'wlan',
  ETHERNET = 'ethernet',
  FOURG = '4g'
}
// tslint:disable:variable-name
export class Device extends BaseModel {
  public monitored_unit_id ?: number;
  public id?: string;
  public ccid?: string;
  public hardware_version?: string;
  public software_version?: string;
  public serial_number?: string;
  public type?: DeviceType;
  public connection_status?: boolean;
  public connection_type?: keyof typeof NetworkConnectionType;
  public connection_secured: boolean;
  public  available_strain_gauges: string[];
  public last_interaction: Date;
}
