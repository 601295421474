<form [formGroup]='formGroup'>
  <div class='row'>
    <div class='col-md-3 indicatif mat-form-field-outline'>
      <ng-select
        style='margin-top: -5px;'
        class="mat-form-field auto-width-panel" appearance="outline"
        [items]='countriesCodes'
        [clearable]='false'
        bindLabel='countryISO'
        (change)="handleIndicatifChange()"
        [(ngModel)]='selectedCountryCode'
        [notFoundText]="'N/A'"
        [placeholder]="'website.users.dialog.tabs.contact.fields.indicatif' | translate"
        aria-label="Type de fluide"
        formControlName="indicatif"
      ></ng-select>
    </div>
    <div class='col-md-9'>
      <mat-form-field class='col-12 mt-2' appearance="outline">
        <mat-label>{{ 'website.users.dialog.tabs.contact.fields.phone' | translate }}</mat-label>
        <input
          matInput
          name="phone"
          formControlName="phone"
          placeholder="{{ 'website.users.dialog.tabs.contact.fields.phone' | translate }}"
          (change)="handlePhoneChange($event)"
          [formControl]="phoneControl"
        />
      </mat-form-field>
    </div>

  </div>
</form>

