import { Component, HostListener, Inject } from '@angular/core';
import { FeaturesFlagService } from '@shared/services/features-flag.service';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '@shared/services/profile.service';
import { Permission } from '@users/domain/services/user.enums';
import { UserService } from '@users/domain/services/user.service';
import { Meta } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(@Inject(DOCUMENT) private document: Document, private meta: Meta, private featuresFlagsService: FeaturesFlagService, private  translate: TranslateService) {
    this.featuresFlagsService.getAllFeaturesFlags();


    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.document.documentElement.lang = event.lang;
      const value = { name: 'description', content: this.translate.instant('META.DESCRIPTION') };
      this.meta.addTag(value);
    });
  }
}
