import {
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  Directive, ViewContainerRef, TemplateRef, EventEmitter
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, combineLatest, merge, Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';
import { FloatLabelType, MatFormField } from '@angular/material/form-field';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-multi-select-autocomplete',
  templateUrl: './multi-select-autocomplete.component.html',
  styleUrls: ['./multi-select-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectAutocompleteComponent),
      multi: true
    }
  ], animations: [
    trigger('dropdownAnimation', [
      state('void', style({
        opacity: 0,
      })),
      state('*', style({
        opacity: 1,

      })),
      transition('void => *', [
        animate('100ms ease-out')
      ]),
      transition('* => void', [
        animate('100ms ease-in')
      ])
    ])
  ]
})
export class MultiSelectAutocompleteComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() items: any[] = [];
  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() multiple = true;
  @Input() bindOptGroup: string;
  @Input() bindOptGroupItems: string;
  @Input() label = '';
  @Input() classListDropdown: string;
  @Input() classListFormField: string;
  @Input() isLoading = false;
  @ViewChild('searchMatFormField', { static: true }) searchMatFormField: MatFormField;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('dropdownContainer', { static: false }) dropdownContainer: ElementRef;
  subscriptions: Subscription = new Subscription();
  floatLabelOption: FloatLabelType = 'auto';
  searchControl = new FormControl();
  selectedItems: any[] = [];
  items$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  showDropdown = false;
  placeholderDisplayText: string;
  filteredItems: any[];
  onChange: (value: any) => void = () => {
  }
  onTouched: () => void = () => {
  }

  constructor(private translateService: TranslateService) {
    this.placeholderDisplayText = this.translateService.instant('website.multi_select_autocomplete.search');
  }

  ngOnChanges(changes: SimpleChanges): void {
    const itemsChange = changes.items;
    if (itemsChange?.currentValue){
      this.items = itemsChange.currentValue;
      this.items$.next(this.items);
    }
  }

  getViewportHeight(): number {
    const itemSize = 50;
    let maxVisibleItems = Math.min(this.filteredItems.length, 50);

    if (this.bindOptGroup) {
      maxVisibleItems = this.filteredItems.reduce((acc, items) => {
        return acc + (_.get(items, this.bindOptGroupItems) as any[])?.length + 1;
      }, 0);
    }

    return Math.min(maxVisibleItems * itemSize, 190);
  }

  setFloatLabelValue(): void {
    let labelType: FloatLabelType = 'auto';
    if (this.selectedItems?.length > 0){
      labelType = 'always';
    }
    this.floatLabelOption = labelType;
  }

  ngOnInit(): void {
    const filteredItems$ = combineLatest([this.items$, this.searchControl.valueChanges.pipe(startWith(''), debounceTime(300))]).pipe(
      map(([items]) => this._filterItems(items)),
    ).subscribe((filteredItems) => {
      this.filteredItems = filteredItems;
    });
    this.subscriptions.add(filteredItems$);
  }



  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (
      this.searchMatFormField &&
      !this.searchMatFormField._elementRef.nativeElement?.contains(event.target) &&
      this.dropdownContainer &&
      !this.dropdownContainer.nativeElement.contains(event.target)
    ) {
      this.hideDropdown();
      this.searchInput.nativeElement.blur();
      if (this.onTouched) {
        this.onTouched();
      }
    }
  }
  private _filterItems(items: any[]): any[] {
    const filterValue = this.searchControl.value?.toLowerCase().trim();
    const sortedItems = items.slice().sort((a, b) => {
      if (this.isSelected(a)) {
        return -1;
      } else {
        return 1;
      }
    });

    if (filterValue) {
      return this._filter(filterValue);
    } else {
      return sortedItems;
    }
  }

  private _filter(value: string): any[] {
    const filterValue = value?.toLowerCase();
    return this.items?.reduce((acc, option) => {
      if (this.bindOptGroup) {
        const groupItems = _.get(option, this.bindOptGroupItems) || [];
        const filteredGroupItems = groupItems.filter(val => _.get(val, this.bindLabel)?.toLowerCase().includes(filterValue));
        if (filteredGroupItems.length) {
          acc.push({
            ...option,
            [this.bindOptGroupItems]: filteredGroupItems
          });
        }
      } else {
        if (this.getOptionLabel(option).toLowerCase().includes(filterValue)) {
          acc.push(option);
        }
      }
      return acc;
    }, []);
  }


  setTextToDisplay(): void{
    let text = this.translateService.instant('website.multi_select_autocomplete.search');
    if (this.selectedItems?.length > 2){
      text = `${this.selectedItems.length} ${this.translateService.instant('website.multi_select_autocomplete.items_selected_plural')}`;
    }else if (this.selectedItems?.length > 0){
      text = this.selectedItems?.map((value) => this.getOptionLabel(value))?.join(', ');
    }
    this.placeholderDisplayText = text;
  }
  handleBlur(): void {
    if (this.showDropdown){
      this.searchInput.nativeElement.focus()      ;
    }
  }

  toggleSelection(option: any): void {
    const index = this.selectedItems?.findIndex(val => _.isEqual(val, option));
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(option);
    }

    this.setFloatLabelValue();
    this.setTextToDisplay();
    if (this.bindValue){
      this.onChange(this.selectedItems.map((value) => _.get(value, this.bindValue)));
    }else{
      this.onChange(this.selectedItems);
    }

  }

  isSelected(option: any): boolean {
    return this.selectedItems?.some(val => _.isEqual(val, option));
  }

  openDropdown(): void {
    this.searchInput.nativeElement.focus() ;
    this.showDropdown = true;

  }

  toggleDropdown(): void{
    this.showDropdown = !this.showDropdown;
    if (this.showDropdown){
      this.openDropdown();
    }else{
      this.hideDropdown();
    }
    this.setFloatLabelValue();
    this.setTextToDisplay();
  }

  hideDropdown(): void {
    this.searchInput.nativeElement.blur();
    this.showDropdown = false;
    this.searchControl.patchValue('');

  }

  getOptionLabel(option: any): string {
    return _.get(option, this.bindLabel);
  }


  getValue(object: any, key: string): string {
    return _.get(object, key);
  }



  writeValue(value: any): void {
    if (value === null || value === undefined){
      value = [];
    }
    this.selectedItems = value;
    this.setFloatLabelValue();
    this.setTextToDisplay();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Add your logic here if you want to handle the disabled state
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public handleFocus(): void {
    if (this.isInputFocused()){
      this.searchInput.nativeElement.blur();
      this.setFloatLabelValue();
    }else{
      this.searchInput.nativeElement.focus();
      this.setFloatLabelValue();
    }
    if (this.isInputFocused()){
      this.hideDropdown();
    }else{
      this.searchInput.nativeElement.click();
      this.openDropdown();
    }
  }

  isInputFocused(): boolean {
    return document.activeElement === this.searchInput.nativeElement;
  }


}
